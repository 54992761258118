.react-select__control {
  display: flex;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.09) !important;
  border: none !important;
}

.react-select__menu {
  background-color: #424242 !important;
  z-index: 9999 !important;
}

.react-select__option {
  color: white !important;
  z-index: 999;
  opacity: 1;
}

.react-select__option--is-focused {
  color: black !important;
}

.react-select__single-value {
  color: white !important;
}

.css-b8ldur-Input {
  color: white !important;
}
