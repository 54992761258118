$padding: 12px;
$margin: 12px;

$font-size: 16px;

$bold-font: 600;

$blue-lagoon: #005677;
$dark-green: #006400;
$dark-color: black;
$light-color: white;
$red: #f44336;
$text-label: #a0c0c0;

$border-radius: 5px;
$border-width: 1px;
$border-style: solid;
$border-color: rgba(255, 255, 255, 0.23);

$dark-background-color: #333333;
