@import "@styles";

.modal {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.textFieldWrapper {
  max-width: 300px;
}

.infoContainer {
  padding: $padding;
}

.buttonsBlock {
  display: flex;
  justify-content: center;
  width: 100%;

  & button {
    margin: $margin;
  }
}

.field {
  overflow: hidden;
  text-overflow: ellipsis;

  :first-child {
    @include Label;
    display: inline-block;
    margin-right: 8px;
  }
}
