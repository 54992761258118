html {
  height: 100%;
}
body {
  height: 100%;
  background-color: #333333;
}

#root {
  height: 100%;
}
