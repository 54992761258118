@import "@styles/varriables";

@mixin Label {
  color: $text-label;
  font-weight: $bold-font;
}

@mixin AlignWithInput {
  display: flex;
  align-items: flex-end;
  height: 100%;

  & > div {
    padding-bottom: $padding !important;
  }
}
